<template>
    <div>
        <h2 class="mb-1">Discharge Data Report</h2>

        <div style="display: flex; flex-wrap: wrap; align-items: center;" class="mb-2">
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon mr-50"
                style="height: 37px; margin-top: 12px;"
                @click.stop.prevent="prevRange()"
            >
                <feather-icon icon="ChevronLeftIcon" />
            </b-button>

            <b-form-group
                label="Start Date"
                class="mr-50 w-10"
            >
                <date-picker v-model="startDate" type="month" format="MM-DD-YYYY" value-type="DD/MM/YYYY" v-if="selectedRangeDate === 'month' || selectedRangeDate === 'quarter'"></date-picker>
                <date-picker v-model="startDate" type="year" format="MM-DD-YYYY" value-type="DD/MM/YYYY" v-else-if="selectedRangeDate === 'year'"></date-picker>
                <date-picker v-model="startDate" type="date" format="MM-DD-YYYY" value-type="DD/MM/YYYY" v-else-if="!selectedRangeDate || selectedRangeDate === 'custom'"></date-picker>
            </b-form-group>
            <b-form-group
                label="End Date"
                class="mr-50"
            >
                <date-picker v-model="endDate" type="date" format="MM-DD-YYYY" value-type="DD/MM/YYYY" :disabled="selectedRangeDate === 'month' || selectedRangeDate === 'quarter' || selectedRangeDate === 'year' ? true : false"></date-picker>
            </b-form-group>

            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon ml-0"
                style="height: 37px; margin-top: 12px;"
                @click.stop.prevent="nextRange()"
            >
                <feather-icon icon="ChevronRightIcon" />
            </b-button>

            <div :class="!selectedRangeDate ? 'pl-1' : 'pl-1'">
                <b-form-group>
                    <b-form-radio-group
                        v-model="selectedRangeDate"
                        button-variant="outline-primary range-date"
                        buttons
                        class="p-0 mr-2"
                    >
                        <b-form-radio style="height: 37px; padding: 11px;" v-for="(option,index) in optionsRangeDate" :key="index" :value="option.value">{{option.text}}</b-form-radio>
                        <b-form-radio style="height: 37px; padding: 10px;" v-if="selectedRangeDate" value="x">x</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>

            <b-form-group
                label="Region"
            >
                <v-select
                    v-model="region"
                    placeholder="Region"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionsRegion"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2"
                />
            </b-form-group>

            <div v-if="region === 'NYC'">
                <b-form-group
                    label="Borough"
                >
                    <v-select
                        v-model="borough"
                        placeholder="Any Borough"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="optionsBorough"
                        style="background: #fff; min-width: 200px;"
                        class="mr-2"
                    />
                </b-form-group>
            </div>
            
            <b-form-group
                label="Progress"
            >
                <v-select
                    v-model="progress"
                    placeholder="Progress"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionsClientProgress"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2"
                />
            </b-form-group>
            
            <b-form-group
                label="Language"
            >
                <v-select
                    v-model="language"
                    placeholder="Language"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionsLanguage"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2"
                />
            </b-form-group>
            
            <b-form-group
                label="Insurance"
            >
                <v-select
                    v-model="insurance"
                    placeholder="Insurance"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionsInsurance"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2"
                />
            </b-form-group>

            <b-form-group
                label="Program"
            >
                <b-form-select
                    v-model="clinicianProgram"
                    inline
                    :options="optionsProgram"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    style="min-width: 200px;"
                    class="d-inline-block mr-2"
                />
                </b-form-group>

            <div>
                <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-primary"
                    class="mb-1 mt-2 p-75"
                    @click="activeFilterFunction"
                >
                    <feather-icon
                        icon="Xicon"
                        class="mr-50"
                    />
                    <span class="align-middle">Reset filters</span>
                </b-button>
            </div>
        <!-- </b-row> -->
        </div>

        <b-row>
            <!-- Total Discharges -->
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countTotalDischarges && countTotalDischarges !== 'NaN' ? countTotalDischarges.toLocaleString('en-US') : 0 }}
                        </h2>
                        <span>Total Discharges</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <!-- Total Planned Discharges -->
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countTotalDischargeStatusPlanned && countTotalDischargeStatusPlanned !== 'NaN' ? countTotalDischargeStatusPlanned.toLocaleString('en-US') : 0 }}
                        </h2>
                        <span>Total Planned Discharges</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <!-- Total Dropout Discharges -->
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countTotalDischargeStatusDropout && countTotalDischargeStatusDropout !== 'NaN' ? countTotalDischargeStatusDropout.toLocaleString('en-US') : 0 }}
                        </h2>
                        <span>Total Dropout Discharges</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <!-- Average Sessions Completed -->
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countAverageSessionsCompleted && countAverageSessionsCompleted !== 'NaN' ? countAverageSessionsCompleted.toLocaleString('en-US') : 0 }}
                        </h2>
                        <span>Average Sessions Completed</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <!-- Goals Achieved -->
            <!-- <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countGoalsAchieved && countGoalsAchieved !== 'NaN' ? countGoalsAchieved.toLocaleString('en-US') : 0 }}%
                        </h2>
                        <span>Goals Achieved</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col> -->

            <!-- Goals Partially Achieved -->
            <!-- <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countGoalsPartiallyAchieved && countGoalsPartiallyAchieved !== 'NaN' ? countGoalsPartiallyAchieved.toLocaleString('en-US') : 0 }}%
                        </h2>
                        <span>Goals Partially Achieved</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col> -->

            <!-- No Longer Gambling or Reduced -->
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        <!-- {{ totalRemoteSessions }} -->
                        {{ countNoLongerGamblingorReduced && countNoLongerGamblingorReduced !== 'NaN' ? countNoLongerGamblingorReduced.toLocaleString('en-US') : 0 }}%
                        </h2>
                        <span>No Longer Gambling or Reduced</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
            
            <!-- Diagnostic Criteria Reduced -->
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        <!-- {{ totalRemoteSessions }} -->
                        {{ countDiagnosticCriteriaReduced && countDiagnosticCriteriaReduced !== 'NaN' ? countDiagnosticCriteriaReduced.toLocaleString('en-US') : 0 }}%
                        </h2>
                        <span>Diagnostic Criteria Reduced</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <!-- Average Length Of Stay -->
            <b-col
                lg="3"
                md="3"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ averageLengthOfStay && averageLengthOfStay !== 'NaN' ? averageLengthOfStay.toFixed(2) : 0 }}  months
                        </h2>
                        <span>Average Length Of Stay</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>

        <download-csv 
            :data="json_data"
            class="mb-1 btn btn-default"
            name="reports-discharge-data.csv"
            :style="readyToExport ? 'background: #94221e; color: #fff; cursor: pointer; opacity: 1;' : 'background: #94221e; color: #fff; cursor: no-drop; opacity: 0.5;'"
            delimiter=";"
            v-if="isDataSpecialist"
        >
        <!-- v-if="isDataSpecialist" -->
            CSV Export
            <b-spinner small v-if="!readyToExport" />
        </download-csv>
    </div>
</template>

<script>
import { 
    BForm, BButton, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BTable, BFormInvalidFeedback, BFormSelect, BCard, BAvatar, BCardBody,
    BSpinner, BFormRadioGroup, BFormRadio,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mapActions, mapGetters, mapState } from 'vuex'
import moment from 'moment'
import { db, auth } from '@/firebase'

export default {
    data() {
        return {
            // validation
            required,
            email,
            totalRows: 0,
            isDataSpecialist: false,
            readyToExport: false,

            // filters
            startDate: null,
            endDate: null,
            selectedRangeDate: 'month',
            optionsRangeDate: [
                { text: 'Month', value: 'month' },
                { text: 'Quarter', value: 'quarter' },
                { text: 'Year', value: 'year' },
                { text: 'Custom', value: 'custom' },
            ],
            region: '',
            borough: '',
            language: '',
            progress: '',
            insurance: '',

            // data reports
            countTotalDischarges : 0,
            countTotalDischargeStatusPlanned : 0,
            countTotalDischargeStatusDropout : 0,
            countAverageSessionsCompleted : 0,
            countGoalsAchieved : 0,
            countGoalsPartiallyAchieved : 0,
            countNoLongerGamblingorReduced: 0,
            countDiagnosticCriteriaReduced: 0,
            averageLengthOfStay: 0,

            // options
            optionsRegion: [],
            // optionsRegion: [
            //     'Bronx',
            //     'Brooklyn',
            //     'Central',
            //     'Finger Lakes',
            //     'Long Island',
            //     'Manhattan',
            //     'Mid-Hudson',
            //     'Northeast',
            //     'Queens',
            //     'Staten Island',
            //     'Western'
            // ],
            optionsLanguage: [],
            optionsClientProgress: [
                'Any Client Status',
                'Engaged',
                'Closed - Attended First Session',
                'Closed - Did not attend first session',
                'Closed - Received information',
                'Closed - Referred to OASAS Program',
                'External Referral',
                'On Hold'
            ],
            optionsInsurance: [],
            optionsBorough: [
                'Bronx',
                'Brooklyn',
                'Manhattan',
                'Queens',
                'Staten Island'
            ],

            // settings table and edit view
            statusEdit: false,
            itemsActivity: [],
            fieldsTable: [
                { key: 'clientID', sortable: false },
                { key: 'clientName', sortable: false },
                { key: 'status', sortable: false },
                { key: 'contactDate', sortable: false },
                { key: 'admissionDate', sortable: false },
                { key: 'dischargeDate', sortable: false },
                // { key: 'actions', sortable: false },
            ],
            clinicianProgram: null,
            optionsProgram: [],

            // get data from db
            optionsClient: [],
            optionsClinician: [],

            // filter
            clientActivityName: null,
            clientActivityStatus: null,

            // json to export csv
            json_data: [],

            // reports cards
            totalGroupSessions: 0,
            totalIndividualSessions: 0,
            totalRemoteSessions: 0,
            totalNonEnglishClients: 0,
            clientSatisfaction: 0,
            improved: 0,
            totalClientActivity: 0,

            // overlay data
            rangeDinamic: false,
            isBusy: true,
        }
    },
    components: {
        BForm,
        BButton,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BTable,
        BFormInvalidFeedback,
        BFormSelect,
        BCard,
        BAvatar,
        BCardBody,
        BSpinner,
        BFormRadioGroup,
        BFormRadio,
        DatePicker,
        
        // select
        vSelect,

        // date
        flatPickr,

        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    computed: {
        ...mapGetters(['arrayFiltradoReportsDischargeData']),
        ...mapState(['searchReportsDischargeDataStartDateState','searchReportsDischargeDataEndDateState','searchReportsDischargeDataRegionState','searchReportsDischargeDataProgressState','searchReportsDischargeDataLanguageState','searchReportsDischargeDataInsuranceState','searchReportsDischargeDataProgramState'])
    },
    watch: {
        selectedRangeDate(val) {
            this.functionDateRange(val)
        },
        clientActivityStatus(val) {
            this.searchClientActivityStatus(val)
        },
        startDate(val) {
            this.searchReportsDischargeDataStartDate(val)
            this.functionDateRange(this.selectedRangeDate)
            this.rangeDinamic ? this.rangeDinamic = false : ''
            // this.selectedRangeDate = ''
        },
        endDate(val) {
            this.searchReportsDischargeDataEndDate(val)
        },
        region(val) {
            this.searchReportsDischargeDataRegion(val)
        },
        borough(val) {
            this.searchReportsDischargeDataRegion(val)
        },
        progress(val) {
            this.searchReportsDischargeDataProgress(val)
        },
        language(val) {
            this.searchReportsDischargeDataLanguage(val)
        },
        insurance(val) {
            this.searchReportsDischargeDataInsurance(val)
        },
        clinicianProgram(val) {
            this.searchReportsDischargeDataProgram(val)
        },
        arrayFiltradoReportsDischargeData(val) {
            this.countTotalDischarges = 0
            this.countTotalDischargeStatusPlanned = 0
            this.countTotalDischargeStatusDropout = 0
            this.countAverageSessionsCompleted = 0
            this.countGoalsAchieved = 0
            this.countGoalsPartiallyAchieved = 0
            this.countNoLongerGamblingorReduced = 0
            this.countDiagnosticCriteriaReduced = 0
            this.averageLengthOfStay = 0

            var totalCountGoalsAchieved = 0
            var totalCountGoalsPartiallyAchieved = 0
            var totalCountNoLongerGamblingorReduced = 0
            var totalCountDiagnosticCriteriaReduced = 0
            var totalAverageSessionsCompleted = 0
            var totalAverageLengthOfStay = 0
            var arrayExport = []
            this.readyToExport = false

            val.forEach(dataClientActivity => {
                if(dataClientActivity.statusLogical === 'Discharged') {
                    // if(dataClientActivity.enterAdmissionDischargeInformation) {
                    // Total Discharges
                    // if((dataClientActivity.dateOfDischarge && dataClientActivity.dateOfDischarge !== 'N/A') && dataClientActivity.statusLogical === 'Discharged') {
                    // if(dataClientActivity.statusLogical === 'Discharged') {
                        // && dataClientActivity.status !== 'On Hold'
                        // console.log(dataClientActivity.id)
                        // idAdmDis
                        this.countTotalDischarges += 1
                    // }
                    
                    if(dataClientActivity.insurance === 'AARP' &&  dataClientActivity.county === 'Albany') {
                        console.log(dataClientActivity.status, dataClientActivity.statusLogical)
                    }

                    if(dataClientActivity.dischargeStatus) {
                        // Total Planned Discharges
                        if(dataClientActivity.dischargeStatus === 'Planned') {
                            this.countTotalDischargeStatusPlanned += 1
                        }

                        // Total Dropout Discharges
                        if(dataClientActivity.dischargeStatus === 'Dropout') {
                            this.countTotalDischargeStatusDropout += 1
                        }
                    }

                    // Average Sessions Completed
                    if(dataClientActivity.numberOfSessionsAttended) {
                        totalAverageSessionsCompleted += 1
                        this.countAverageSessionsCompleted += parseInt(dataClientActivity.numberOfSessionsAttended, 10)
                    }

                    // if(dataClientActivity.goalsMet) {
                    //     // testTotal += 1
                    //     // Goals Achieved
                    //     if (dataClientActivity.goalsMet === 'Achieved') {
                    //         this.countGoalsAchieved += 1
                    //     }
                    //     dataClientActivity.goalsMet !== 'N/A' ? totalCountGoalsAchieved += 1 : ''
                    //     // totalCountGoalsAchieved += 1
                    //     // else {
                    //     //     totalCountGoalsAchieved += 1
                    //     // }
                        
                    //     // Goals Partially Achieved
                    //     if (dataClientActivity.goalsMet === 'Partially Achieved') {
                    //         this.countGoalsPartiallyAchieved += 1
                    //     }
                    //     // if(this.region) {
                    //     //     console.log(dataClientActivity.goalsMet)
                    //     // }
                        
                    //     dataClientActivity.goalsMet !== 'N/A' ? totalCountGoalsPartiallyAchieved += 1 : ''
                    //     // else {
                    //     //     totalCountGoalsPartiallyAchieved += 1
                    //     // }
                    // }

                    // No Longer Gambling or Reduced
                    if(dataClientActivity.currentGamblingComparedToAdmission === 'No longer gambling' || dataClientActivity.currentGamblingComparedToAdmission === 'Reduced gambling') {
                        this.countNoLongerGamblingorReduced += 1
                    }
                    // console.log(dataClientActivity.currentGamblingComparedToAdmission)
                    dataClientActivity.currentGamblingComparedToAdmission !== 'N/A' && dataClientActivity.currentGamblingComparedToAdmission !== '' ? totalCountNoLongerGamblingorReduced += 1 : ''
                    // totalCountNoLongerGamblingorReduced += 1
                    // else {
                    //     totalCountNoLongerGamblingorReduced += 1
                    // }

                    if(dataClientActivity.diagnosticCriteriaMetAtAdmissionWasReduced) {
                        // Diagnostic Criteria Reduced
                        if(dataClientActivity.diagnosticCriteriaMetAtAdmissionWasReduced === 'Yes') {
                            this.countDiagnosticCriteriaReduced += 1
                        }
                        dataClientActivity.diagnosticCriteriaMetAtAdmissionWasReduced !== 'N/A' ? totalCountDiagnosticCriteriaReduced += 1 : ''
                        // totalCountDiagnosticCriteriaReduced += 1
                        // else {
                        //     totalCountDiagnosticCriteriaReduced += 1
                        // }
                    }
                    
                    // average length of stay
                    if(dataClientActivity.admissionDate && dataClientActivity.dateOfDischargeFormat) {
                        var startFormat = moment(dataClientActivity.admissionDate,'MM-DD-YYYY').format('MM/DD/YYYY')
                        var endFormat = moment(dataClientActivity.dateOfDischargeFormat,'DD/MM/YYYY').format('MM/DD/YYYY')

                        var start = moment(startFormat)
                        var end = moment(endFormat)
                        // console.log(start,end)

                        // if() {28/09/2021 06/01/2022
                        var duration = moment.duration(end.diff(start));
                        var months = duration.asMonths();
                        // console.log(months)
                        this.averageLengthOfStay += months
                        // }
                        totalAverageLengthOfStay += 1
                    }
                    // }
                    arrayExport.push({
                        // id: dataClientActivity.,
                        // idAdmDis: dataClientActivity.,
                        // clientID: dataClientActivity.,
                        // clientName: dataClientActivity.,
                        status: dataClientActivity.statusLogical,
                        // statusLogical: dataClientActivity.,
                        contactDate: dataClientActivity.contactDate,
                        // contactDateFormat: dataClientActivity.,
                        admissionDate: dataClientActivity.admissionDate,
                        // dateOfDischargeFormat: dataClientActivity.,
                        enterAdmissionDischargeInformation: dataClientActivity.enterAdmissionDischargeInformation,
                        dateOfDischarge: dataClientActivity.dateOfDischarge,
                        dischargeStatus: dataClientActivity.dischargeStatus,
                        // numberOfSessionsAttended: dataClientActivity.numberOfSessionsAttended,
                        // goalsMet: dataClientActivity.goalsMet,
                        // currentGamblingComparedToAdmission: dataClientActivity.currentGamblingComparedToAdmission,
                        // diagnosticCriteriaMetAtAdmissionWasReduced: dataClientActivity.diagnosticCriteriaMetAtAdmissionWasReduced,
                        region: dataClientActivity.region,
                        clientStatusAdmission: dataClientActivity.clientStatusAdmission ? dataClientActivity.clientStatusAdmission : '',
                        primaryICD10: dataClientActivity.primaryICD10 ? dataClientActivity.primaryICD10 : '',
                        locadtrgCompleted: dataClientActivity.locadtrg ? dataClientActivity.locadtrg : '',
                        LOCADTRAssessmentID: dataClientActivity.LOCADTRAssessmentID ? dataClientActivity.LOCADTRAssessmentID : '',

                        // new fields
                        plannedDischargeReasons: dataClientActivity.plannedDischargeReasons ? dataClientActivity.plannedDischargeReasons : '',
                        unplannedDischargeReason: dataClientActivity.unplannedDischargeReason ? dataClientActivity.unplannedDischargeReason : '',
                        numberOfSessionsAttended: dataClientActivity.numberOfSessionsAttended ? dataClientActivity.numberOfSessionsAttended : '',
                        diagnosticCriteriaMetAtAdmissionWasReduced: dataClientActivity.diagnosticCriteriaMetAtAdmissionWasReduced ? dataClientActivity.diagnosticCriteriaMetAtAdmissionWasReduced : '',
                        diagnosticCriteriaMetAtDischarge: dataClientActivity.diagnosticCriteriaMetAtDischarge ? dataClientActivity.diagnosticCriteriaMetAtDischarge : '',
                        // goalsMet: dataClientActivity.goalsMet ? dataClientActivity.goalsMet : '',
                        currentGamblingComparedToAdmission: dataClientActivity.currentGamblingComparedToAdmission ? dataClientActivity.currentGamblingComparedToAdmission : '',
                        clientInvolvedWithSelfHelpAtDischarge: dataClientActivity.clientInvolvedWithSelfHelpAtDischarge ? dataClientActivity.clientInvolvedWithSelfHelpAtDischarge : '',
                        clientReferredForContinuedServices: dataClientActivity.clientReferredForContinuedServices ? dataClientActivity.clientReferredForContinuedServices : '',
                        ifSoWhatServicesWereRecommended: dataClientActivity.ifSoWhatServicesWereRecommended ? dataClientActivity.ifSoWhatServicesWereRecommended : '',
                        
                        // client information
                        clientID: dataClientActivity.clientID ? dataClientActivity.clientID : '',
                        insurance: dataClientActivity.insurance ? dataClientActivity.insurance : '',
                        county: dataClientActivity.county ? dataClientActivity.county : '',
                        language: dataClientActivity.language ? dataClientActivity.language : '',
                        // gender: dataClientActivity.gender ? dataClientActivity.gender : '',
                        program: dataClientActivity.clinicianProgram ? dataClientActivity.clinicianProgram : ''
                    })
                }
            })
            // console.log(this.countNoLongerGamblingorReduced,totalCountNoLongerGamblingorReduced)
            // console.log(totalCountGoalsAchieved,this.countGoalsAchieved)
            // console.log(this.averageLengthOfStay,totalAverageLengthOfStay)
            this.averageLengthOfStay = this.averageLengthOfStay / totalAverageLengthOfStay
            this.countAverageSessionsCompleted = Math.trunc(this.countAverageSessionsCompleted / totalAverageSessionsCompleted)
            this.countGoalsAchieved = ((this.countGoalsAchieved*100)/totalCountGoalsAchieved).toFixed(2)
            this.countGoalsPartiallyAchieved = ((this.countGoalsPartiallyAchieved*100)/totalCountGoalsPartiallyAchieved).toFixed(2)
            this.countNoLongerGamblingorReduced = ((this.countNoLongerGamblingorReduced*100)/totalCountNoLongerGamblingorReduced).toFixed(2)
            this.countDiagnosticCriteriaReduced = ((this.countDiagnosticCriteriaReduced*100)/totalCountDiagnosticCriteriaReduced).toFixed(2)
            this.isBusy = false

            // this.json_data = val
            this.json_data = arrayExport
            this.readyToExport = true
        }
    },
    methods: {
        ...mapActions(['searchClientActivityName','searchClientActivityStatus','searchClientActivityClinicianName','searchReportsDischargeDataRegion','searchReportsDischargeDataProgress','searchReportsDischargeDataLanguage','searchReportsDischargeDataInsurance','searchClientActivityClinicianStatus','searchClientActivityClientID','searchReportsDischargeDataStartDate','searchReportsDischargeDataEndDate','setSnapshotClientActivityReportsDischargeData','searchReportsDischargeDataProgram']),
        activeFilterFunction() {
            this.startDate = null
            this.endDate = null
            this.selectedRangeDate = 'month'
            this.region = ''
            this.language = ''
            this.progress = ''
            this.insurance = ''
            this.clinicianProgram = null
            // this.functionFilters()
            this.activeFilters = false
        },
        functionDateRange(val) {
            if(val === 'month') {
                // console.log(this.startDate)
                if(this.startDate) {
                    // '24/03/2022'
                    var formatDate1 = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                    var formatDate = moment(this.startDate,'DD/MM/YYYY').format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(1, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,expiredDate,dateUpdateFormat)
                } else {
                    var formatDate1 = moment(Date.now()).format('MM/DD/YYYY')
                    var formatDate = moment(Date.now()).format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(1, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(this.startDate,this.endDate)
                }
            } else if(val === 'quarter') {
                if(this.startDate) {
                    var formatDate1 = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                    var formatDate = moment(this.startDate,'DD/MM/YYYY').format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(3, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,expiredDate,dateUpdateFormat)
                } else {
                    var formatDate1 = moment(Date.now()).format('MM/DD/YYYY')
                    var formatDate = moment(Date.now()).format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(3, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,expiredDate,dateUpdateFormat)
                }
            } else if(val === 'year') {
                if(this.startDate) {
                    var formatDateNew = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                    var getYear = moment(formatDateNew).format('YYYY')
                    var formatDate1 = moment('01/01/'+getYear).format('MM/DD/YYYY')
                    var formatDate = '01/01/'+getYear
                    var expiredDate = moment(formatDate1).add(12, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,expiredDate,dateUpdateFormat)
                } else {
                    var getYear = moment(Date.now()).format('YYYY')
                    var formatDate1 = moment('01/01/'+getYear).format('MM/DD/YYYY')
                    var formatDate = moment('01/01/'+getYear).format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(12, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,dateUpdateFormat)
                }
            } else if(val === 'x') {
                this.selectedRangeDate = ''
                this.startDate = null
                this.endDate = null
            }
        },
        prevRange() {
            if(this.startDate && this.selectedRangeDate) {
                var formatDate = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                var subtractDate = moment(formatDate).subtract(1, 'months')
                var dateUpdateFormat = moment(subtractDate,'MM/DD/YYYY').format('DD/MM/YYYY')

                this.rangeDinamic = true
                this.startDate = dateUpdateFormat
                this.functionDateRange(this.selectedRangeDate)
            }
        },
        nextRange() {
            if(this.startDate && this.selectedRangeDate) {
                var formatDate = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                var subtractDate = moment(formatDate).add(1, 'months')
                var dateUpdateFormat = moment(subtractDate,'MM/DD/YYYY').format('DD/MM/YYYY')

                this.rangeDinamic = true
                this.startDate = dateUpdateFormat
                this.functionDateRange(this.selectedRangeDate)
            }
        },
        functionFilters() {
            if(this.clientActivityStatus) {
                this.searchClientActivityStatus(this.clientActivityStatus)
            } else {
                this.searchClientActivityStatus('')
            }

            if(this.startDate) {
                this.searchReportsDischargeDataStartDate(this.startDate)
            } else {
                this.searchReportsDischargeDataStartDate('')
            }

            if(this.endDate) {
                this.searchReportsDischargeDataEndDate(this.endDate)
            } else {
                this.searchReportsDischargeDataEndDate('')
            }

            if(this.region) {
                this.searchReportsDischargeDataRegion(this.region)
            } else {
                this.searchReportsDischargeDataRegion('')
            }

            if(this.progress) {
                this.searchReportsDischargeDataProgress(this.progress)
            } else {
                this.searchReportsDischargeDataProgress('')
            }

            if(this.language) {
                this.searchReportsDischargeDataLanguage(this.language)
            } else {
                this.searchReportsDischargeDataLanguage('')
            }

            if(this.insurance) {
                this.searchReportsDischargeDataInsurance(this.insurance)
            } else {
                this.searchReportsDischargeDataInsurance('')
            }

        }
    },
    created() {
        var user = auth.currentUser
        db.collection('usuarios').doc(user.uid).get()
        .then(doc => {
            this.isDataSpecialist = false
            if(doc.data().role === 'staff') {
                if(doc.data().permissions.includes('data specialist')) {
                    this.isDataSpecialist = true
                }
            } else if(doc.data().role === 'admin') {
                this.isDataSpecialist = true
            }
        })

        // filters
        this.startDate = this.searchReportsDischargeDataStartDateState ? this.searchReportsDischargeDataStartDateState : null
        this.endDate = this.searchReportsDischargeDataEndDateState ? this.searchReportsDischargeDataEndDateState : null
        this.region = this.searchReportsDischargeDataRegionState ? this.searchReportsDischargeDataRegionState : ''
        this.progress = this.searchReportsDischargeDataProgressState ? this.searchReportsDischargeDataProgressState : ''
        this.language = this.searchReportsDischargeDataLanguageState ? this.searchReportsDischargeDataLanguageState : ''
        this.insurance = this.searchReportsDischargeDataInsuranceState ? this.searchReportsDischargeDataInsuranceState : ''
        this.clinicianProgram = this.searchReportsDischargeDataProgramState ? this.searchReportsDischargeDataProgramState : null

        this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/prod/discharge-data/reports')
        .then(res => {
            this.countTotalDischarges = res.data.counter.countTotalDischarges
            this.countTotalDischargeStatusPlanned = res.data.counter.countTotalDischargeStatusPlanned
            this.countTotalDischargeStatusDropout = res.data.counter.countTotalDischargeStatusDropout
            this.countAverageSessionsCompleted = res.data.counter.countAverageSessionsCompleted
            this.countGoalsAchieved = res.data.counter.countGoalsAchieved
            this.countGoalsAchieved = ((res.data.counter.countGoalsAchieved*100)/res.data.counter.totalClientActivity).toFixed(2)
            this.countGoalsPartiallyAchieved = res.data.counter.countGoalsPartiallyAchieved
            this.countNoLongerGamblingorReduced = res.data.counter.countNoLongerGamblingorReduced
            this.countDiagnosticCriteriaReduced = res.data.counter.countDiagnosticCriteriaReduced
            this.totalClientActivity = res.data.counter.totalClientActivity
            this.isBusy = false
            // console.log(res.data)
            this.setSnapshotClientActivityReportsDischargeData(res.data.activity)
        })
        .catch(error => {
            console.log(error)
        })

        db.collection('clients').get()
        .then(docsClients => {
            this.totalNonEnglishClients = 0
            this.clientSatisfaction = 0
            docsClients.forEach(itemClient => {
                if(itemClient.data().status === 'active') {
                    var languageLowerCase = itemClient.data().primaryLanguage.toLowerCase()
                    if(!languageLowerCase.includes("english")) {
                        this.totalNonEnglishClients += 1
                    }
                }
            })
        })

        db.collection('taxonomies').doc('client').get()
        .then(docTaxonomia => {
            // profile fields
            // this.optionsRegion = docTaxonomia.data().region
            this.optionsLanguage = docTaxonomia.data().primaryLanguage
            this.optionsInsurance = docTaxonomia.data().insuranceType
        })

        db.collection('taxonomies').doc('clinician').get()
        .then(docTaxonomia => {
            // profile fields
            this.optionsRegion = docTaxonomia.data().region

            var arrayProgram = [{value: null, text: 'Any Program'}]
            docTaxonomia.data().program.forEach(item => {
                if(item !== 'OASAS') {
                    arrayProgram.push({value: item, text: item})
                }
            })
            this.optionsProgram = arrayProgram
        })
    }
}
</script>

<style>
.mx-input {
    height: 37px !important;
}
.form-control {
    background: #fff !important;
    background-color: #fff !important;
}
.range-date {
    padding: 8px;
    margin-top: 27px;
}
.vs__dropdown-option {
  white-space: normal !important;
}
.vs__dropdown-toggle {
  max-height: 38px;
}
.vs__selected-options {
  overflow: auto;
}
.vs__selected-options {
    overflow-x: hidden;
    overflow-y: hidden;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>